@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
}


#editorjs, #editorjs2, #editorjs3 {
  background-color: #f5f5f5;
  padding: 20px;
}
.app {
  display: flex;
  position: relative;
}

.app2 {
    display: block;
    width: 100%;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.file-input {
  display: none;
}

.file-icon {
  width: 48px;
  margin: 16px;
}


#container-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 600px;
  background-color: rgb(255, 234, 234);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
}

#service-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

#toolbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 50%;
}

#app-color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
  width: 100%;

}

.state {
  opacity: 0.2;
}

.state-active {
  opacity: 1;
  transform: scale(1.3);
}


.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fcfcfc;
  border-radius: 10px;
  width: 50%;
  height: 100px;
  position: absolute;
  bottom: 30px;
  z-index: 100;
  left: 50%;
  transform: translateX(-70%);
}